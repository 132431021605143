import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SEO, Container, Banner, ProjectPopup, Modal, FadeIn } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { H4, P } from '../../styles/mixins';

export const query = graphql`
  query InFramePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: sanityCategory(slug: { current: { eq: "heritage" } }) {
      categoryImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    projects: allSanityProject(
      filter: { projectCategory: { slug: { current: { eq: "heritage" } } } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          _rawBody
          title
          projectImages {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
            caption
          }
          projectImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
            caption
          }
          _id
          slug {
            current
          }
        }
      }
    }
  }
`;

const InFramePage = (props) => {
  const { data } = props;
  const { site } = data || {};
  const { bannerImage } = data;
  const { edges: projects } = data.projects;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const banner = {
    caption: 'Heritage Kitchens',
    text: 'To view a selection of our recent heritage kitchen designs below, simply click a tile to open the gallery..',
    sanityImage: bannerImage.categoryImage
  };

  return (
    <main>
      <SEO
        title="Heritage Kitchens"
        description="Heritage kitchens have a more traditional background. However we don’t feel the heritage should be limited to just that. We custom make every Heritage Kitchen in-house, so there is no limit on what we can create for our clients."
        keywords={site.keywords}
      />
      <Banner banner={banner} />
      <Description>
        Heritage kitchens have a more traditional background. However we don’t feel the heritage
        should be limited to just that. We custom make every Heritage Kitchen in-house, so there is
        no limit on what we can create for our clients. All Kitchens are about attention to detail
        and a Heritage Kitchen emphasis detail in every respect .
      </Description>
      <Container>
        <FadeIn>
          <Grid>
            {projects.map(({ node }, i) => (
              <Modal
                key={i}
                trigger={
                  <GridItem>
                    <Content>
                      <GatsbyImage
                        image={node.projectImage.asset.gatsbyImageData}
                        alt={node.title}
                      />
                      <Title>
                        <H4>{node.title}</H4>
                      </Title>
                    </Content>
                  </GridItem>
                }
                modalContent={<ProjectPopup node={node} />}
              />
            ))}
          </Grid>
        </FadeIn>
      </Container>
    </main>
  );
};

const Description = styled(P)`
  width: 100%;
  max-width: 750px;
  padding: 1rem;
  margin: 1rem auto;
  text-align: center;
`;

const Title = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  background: none;
  backdrop-filter: blur(3px);

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
  transition: 0.5s;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  opacity: 0.75;
  transition: 0.5s;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const GridItem = styled.button`
  border: none;
  outline: none;
  background: none;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover ${Title} {
    opacity: 1;
  }
  &:hover ${Content} {
    opacity: 1;
  }
  .gatsby-image-wrapper {
    aspect-ratio: 1/1;
    height: auto;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  padding: 1rem;
  align-items: start;

  @media (min-width: 768px) {
    padding: 0;
    margin: 3rem auto;
    margin-bottom: 8rem;
    max-width: 720px;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default InFramePage;
